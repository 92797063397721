<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
        
        <div>
            <tabsComp :tabs="tabs" />
            
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
                <v-row class="mt-10">
      
                    <v-col
                    cols="12"
                    md="100"
                    style="margin-top:80px;margin-bottom:80px;"
                    sm="12">
                    <v-data-table
                        :headers="headers"
                        :items="tbRows"
                        hide-default-footer
                        disable-pagination
                    >
                        <template v-slot:header="" >
                            <thead>
                                <tr>
                                    <th class="text-center" colspan="10" style="font-weight:bold;font-size:1rem;">{{ lang.coa_report_with_totals }}</th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item="row">
                            <tr>
                                <td class="text-center" style="width:70px">{{ row.item.classid }}</td>
                                <td class="text-center" style="">{{ row.item[`name${lang.langname}`] }}</td>
                                <td class="text-center" style="width:70px">{{ row.item.parentid }}</td>
                                <td class="text-center" style="">{{ row.item[`parent_name${lang.langname}`] }}</td>
                                <td class="text-center" style="">{{ row.item[`type_${lang.langname}`] }}</td>
                                <td class="text-center" style="">{{ row.item[`account_type_${lang.langname}`] }}</td>
                                <td class="text-center" style="">{{ row.item[`default_${lang.langname}`] }}</td>
                                <td class="text-center" style="">{{ $RoundNum(row.item.debit,2) }}</td>
                                <td class="text-center" style="">{{ $RoundNum(row.item.credit,2) }}</td>
                                <td class="text-center" style="">{{ $RoundNum(row.item.balance,2) }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                    </v-col>
                </v-row>
            </v-row>
        </div>
        <Footer />
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue';
import ExportsPrint from '@/components/exports-print.vue';
import Footer from '@/components/Footer.vue';
import tabsComp from '@/components/tabsComp.vue'
import axios from 'axios'
export default {
    components: {
        ExportsPrint, Footer, tabsComp, HeaderPortrate
    },
    data() {
        return {
            tbRows: []
        }
    },
    computed: {
        lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
      },
      headers: function(){
        return [
            {
                text: this.lang.classid,
                align: 'center',
                sortable: false,
                value: 'classid',
                class:"backBlack",
                printRemove: 0
            },
            {
                text: this.lang.class_name,
                align: 'center',
                sortable: false,
                value: 'class_name',
                class:"backBlack",
                printRemove: 0,
            },
            {
                text: this.lang.parent_classid,
                align: 'center',
                sortable: false,
                value: 'parent_classid',
                class:"backBlack",
                printRemove: 0,
            },
            {
                text: this.lang.parent_class_name,
                align: 'center',
                sortable: false,
                value: 'parent_class_name',
                class:"backBlack",
                printRemove: 0,
            },
            {
                text: this.lang.accoutn_option,
                align: 'center',
                sortable: false,
                value: 'type',
                class:"backBlack",
                printRemove: 0,
            },
            {
                text: this.lang.account_type,
                align: 'center',
                sortable: false,
                value: 'account_type',
                class:"backBlack",
                printRemove: 0,
            },
            {
                text: this.lang.default_type,
                align: 'center',
                sortable: false,
                value: 'class_default',
                class:"backBlack",
                printRemove: 0,
            },
            {
                text: this.lang.debit,
                align: 'center',
                sortable: false,
                value: 'balance',
                class:"backBlack",
                printRemove: 0,
            },
            {
                text: this.lang.credit,
                align: 'center',
                sortable: false,
                value: 'balance',
                class:"backBlack",
                printRemove: 0,
            },
            {
                text: this.lang.balance,
                align: 'center',
                sortable: false,
                value: 'balance',
                class:"backBlack",
                printRemove: 0,
            },
        ]
      },
      tabs: function()
      {
        const menu = this.$store.getters['state'].menu;
        const license = this.$store.getters['state'].licenseType.activemenu;
        const alicense = this.$store.getters['state'].licenseType
        let currentMenu = menu[license];
        
        if(!license){
            return false;
        }
        let cMenu = {main: {},sub:[]};
        Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            // console.log("cM",cM);
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
        });
        
        let t = []
        // console.log("cMenu",cMenu);
        Object.values(cMenu).forEach(value =>{
        
            if(this.$route.path == value.href){
                value.class = "mytab";
            }else{
                value.class = "mytab4";
            }
            if(this.lang.langname == 'ar'){
                value.name = value.arname
            }else{
                value.name = value.enname
            }
            
            for(let i =0; i< value.groupid.length;i++){
                if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                }
            }
        })
    
        return t;
      }
    },
    methods:{
        getAccounts(){
            const post = new FormData();
            post.append('type','getChartTree');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',0);
            
            this.$store.state.showLoading = true
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                this.$store.state.showLoading = false
                const res = response.data;
                

                this.tbRows = res.results.data;
            });
        }
    },
    created(){
        this.getAccounts()
    }
}
</script>
